import React, { Component } from 'react'
import Navbar from './navbar'
import Footer from './Footer'


export default class Layout extends Component {
  render() {
    const { navbarBackgroundColor,navbarBorderBottomColor } = this.props;
    return (
     <>
     <Navbar backgroundColor={navbarBackgroundColor} borderBottomColor={navbarBorderBottomColor}/>
     {this.props.children}
     <Footer/>
     </>
    )
  }
}
