import { Poppins, Quicksand,Montserrat } from 'next/font/google'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
const popins =Poppins({weight:"500",subsets:['latin']})
const quickSand =Quicksand({weight:"400",subsets:['latin']})
const monserrat =Montserrat({weight:"400",subsets:['latin']})
export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
    <footer>
   <section className='footer-bg'>
     <div className='container'>
        <div className='pdt40 df fdc pdb30 mpdt20'>
      {/* 1st Row       */}
<div className='df fjsa  mfdc fw'>
    <div className='mdf mfjc mfdc mfac'>
        <div className='df  fjc mdn'>
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'> 
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
        <a href='mailto:contact@learnsoft.org'>
             <h6 className={`fs-19 mfs-16 pdt32 mpdt10 cw  tac  ${popins.className}`}>contact@learnsoft.org</h6>
             </a>
        <a href='tel:7825888899'>
        <h6 className={`fs-19 mfs-16 cw pdt10 mpdt10  ${popins.className}`}>+91 7825 88 88 99</h6>
        </a>
        <a href='tel:7825888895'>
        <h6 className={`fs-19 mfs-16 cw pdt10  ${popins.className}`}>+91 7825 88 88 95</h6>
        </a>
        {/* <div className=' pdt20 '>
           {/* <div className=' '>
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"30"} height={"30"} loading='lazy'/> 
             </div> */}
             {/* <a href='mailto:contact@learnsoft.org'>
             <h6 className={`fs-19 mfs-16 mpdt10 cw  tac  ${popins.className}`}>contact@learnsoft.org</h6>
             </a> 
        </div> */}
    </div>
    <div className='df fdc fac'>
         <div className='df fjc mdn'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
          <h5 className='fs-19 mfs-16 mpdt10 ylw-clr pdt32 tac'>Chennai</h5>
        <h6 className={`fs-19 mfs-16 mpdt10 cw mt10 tac ${popins.className}`}>No.4/643,ADM Tower 2nd Floor,<br/>VOC street, OMR, Thoraippakkam,
        <br/>Chennai-600097.</h6>
        </div>
    {/* <div className='mpdt24 mdf mfjc mfdc mfac'>
        <div className='df fjc mdn '>
            <Image src={"/footer-img/email.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/email.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
        <a href='mailto:contact@learnsoft.org'>
        <h6 className={`fs-19 mfs-16 mpdt10 cw pdt80 tac ${popins.className}`}>contact@learnsoft.org</h6>
        </a>
    </div> */}

    {/* <div className='mpdt24 mdf mfjc mfdc mfac'>
        <div className='df fjc mdn'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
        <h6 className={`fs-19 mfs-16 mpdt10 cw pdt32 tac ${popins.className}`}>No.4/643,ADM Tower 2nd Floor,<br/>VOC street, OMR, Thoraippakkam,
        <br/>Chennai-600097.</h6>
    </div> */}
    
    <div className='mpdt24 df fdc gap10 mdf mfjc mfdc mfac'>
    {/* <div className='df fjc mdn'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div> */}
        <div className='df gap20 mfdc'>
          
         {/* <div className='df fdc fac'>
         <div className='df fjc mdn'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
          <h5 className='fs-19 mfs-16 mpdt10 ylw-clr pdt32 tac'>Chennai</h5>
        <h6 className={`fs-19 mfs-16 mpdt10 cw mt10 tac ${popins.className}`}>No.4/643,ADM Tower 2nd Floor,<br/>VOC street, OMR, Thoraippakkam,
        <br/>Chennai-600097.</h6>
        </div> */}
        {/* <span className='mdn vert-code mt20 mmt10'></span> */}
        <div className='df fdc fac'>
        <div className='df fjc mdn'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"100"} height={"100"} loading='lazy'/> 
        </div>
        <div className='df fjc dn mdb'>
            <Image src={"/footer-img/location.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div>
        <h5 className='fs-19 mfs-16 mpdt10 pdt32 tac ylw-clr'>Tirunelveli</h5>
        <h6 className={`fs-19 mfs-16 mpdt10 cw mt10 tac ${popins.className}`}>NO.181A/30/2, Masha Allah Complex<br/> S.N.High Road, Sripuram Tirunelveli
        <br/>Tirunelveli-627007.</h6>
        </div>
        </div>
    </div>
</div>
<span className='line-code mt20 mmt10'></span>

{/* 2nd Row */}




<div className='df fjsa  mfdc'>
<div className='mdn pdt20 mpdt10'>
    <div>
        <Image src={"/footer-img/logo.webp"} alt='LOGO' width={"121"} height={"132"} loading='lazy'/>
    </div>
    <div className=' mdf mfjc mfdc mfac'>
    <p className={`fs-16 cw  para ${quickSand.className}`}>At learnsoft.org, our expert faculty and hands-on training guarantee you acquire practical skills and industry-relevant knowledge to excel in your chosen field.</p>

        {/* <div className='df  fjc mdn pdt40'>
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"30"} height={"30"} loading='lazy'/> 
        </div> */}
        {/* <div className='df fjc dn mdb'> 
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"60"} height={"60"} loading='lazy'/> 
        </div> */}
        {/* <div className='df pdt20'>
           <div className=' '>
            <Image src={"/footer-img/phone.webp"} alt='Phone-Icon' width={"30"} height={"30"} loading='lazy'/> 
             </div>
             <a href='mailto:contact@learnsoft.org'>
             <h6 className={`fs-19 mfs-16 mpdt10 cw  tac ${popins.className}`}>contact@learnsoft.org</h6>
             </a>
        </div> */}

    </div>
    {/* <p className={`fs-16 cw pdt16 para ${quickSand.className}`}>At learnsoft.org, our expert faculty and hands-on training guarantee you acquire practical skills and industry-relevant knowledge to excel in your chosen field.</p> */}
</div>

<div className='mpdt20 pdt130'>

<h6 className={`fs-19 ylw-clr ${popins.className}`}>Company</h6>

<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/blog" className={`cw ${quickSand.className}`}>Blog</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/contact" className={`cw ${quickSand.className}`}>Contact</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/about" className={`cw ${quickSand.className}`}>About us</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/refund" className={`cw ${quickSand.className}`}>Refund Policy</Link>
</p>


</div>


<div className='mpdt20 pdt130'>
<h6 className={`fs-19 ylw-clr ${popins.className}`}>Cloud</h6>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/aws-training" className={`cw ${quickSand.className}`}>AWS Cloud</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/azure-training" className={`cw ${quickSand.className}`}>Azure Cloud</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/google-cloud-training" className={`cw ${quickSand.className}`}>Google Cloud</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/salesforce-training" className={`cw ${quickSand.className}`}>Salesforce</Link>
</p>
</div>

<div className='mpdt20 pdt130'>
<h6 className={`fs-19 ylw-clr ${popins.className}`}>DevOps</h6>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/jenkins-training" className={`cw ${quickSand.className}`}>Jenkins</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/terraform-training" className={`cw ${quickSand.className}`}>Terraform</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/kubernetes-training" className={`cw ${quickSand.className}`}>Kubernetes</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/devsecops-training" className={`cw ${quickSand.className}`}>DevSecOps</Link>
</p>
</div>

<div className='mpdt20 pdt130'>
<h6 className={`fs-19 ylw-clr ${popins.className}`}>Development</h6>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/ui-ux-design-course" className={`cw ${quickSand.className}`}>UI UX Design</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/reactjs-training" className={`cw ${quickSand.className}`}>ReactJS</Link>
</p>
<p className={`fs-16 cw pdt8 hvz`}>
<Link href="/training/flutter-training" className={`cw ${quickSand.className}`}>Flutter</Link>
</p>
<p className={`fs-16 cw pdt8 .hvz `}>
<Link href="/training/nodejs-training" className={`cw ${quickSand.className}`}>NodeJS</Link>
</p>
</div>
</div>

 {/* 3rd Row */}

<span className='line-code mt24'></span>

<div className='df fdc fac fjc'>
  <div className='df pdt41 mpdt20 gap16'>
    <div>
<Link legacyBehavior href={"https://www.instagram.com/learnsoft_org/profilecard/?igsh=MWt4ZzNzeHdwMnp0eQ%3D%3D&utm_source=qr"} target="_blank">
          <a target='blank'>
          <Image src={"/footer-img/insta.webp"} alt='Insta-Icon' width={"46"} height={"47"} loading='lazy'/>
          </a></Link>
    </div>
    <div>
<Link legacyBehavior href={"https://www.facebook.com/profile.php?id=100090640644778&mibextid=LQQJ4d"}>
<a target='blank'>
         <Image src={"/footer-img/fb.webp"} alt='Fb-Icon' width={"46"} height={"47"} loading='lazy'/>
      </a>
      </Link>  
    </div>
    <div>
<Link legacyBehavior href={"https://www.threads.net/@learnsoftorg?xmt=AQGzuhernw4r4G-lKDuvPg_ZWsGAkU3TQomLXUhLwVZFh5c"}>
<a target='blank'>
        <Image src={"/footer-img/x.webp"} alt='X-Icon' width={"46"} height={"47"} loading='lazy'/>
      </a>
      </Link>
    </div>
    <div>
<Link legacyBehavior href={"https://www.linkedin.com/in/learnsoft-organization-it-training-internship-and-placement?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"}>
<a target='blank'>
         <Image src={"/footer-img/linkedin.webp"} alt='Linkedin-Icon' width={"46"} height={"47"} loading='lazy'/>
</a>
</Link>
    </div>

  </div>
  <div className={`cw  pdt32 mpdt20 fs-12 ${monserrat.className}`}> 
     &copy; {currentYear} learnsoft.org 
     <p className=' fs-12 cw tac pdt5'>All Rights Reserved</p>
  </div>
</div>
    </div>
     </div>
   </section>
   </footer>
<style jsx>

    {
        `
      .footer-bg{
        // background-image: url('/footer-img/footer-bg.webp');
        background-color:#000000;
        width:100%;
        height: auto;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;
        // background-position: cover;
        // background-size: 100% 100%;
      }  
      .hvz:hover {
        color: #ffa900;
      }
         .vert-code{
        background: linear-gradient(90deg, rgba(255, 169, 0, 0.1) 0%, #FFA900 50%, rgba(255, 169, 0, 0.1) 100%);
        width:3px;
        height: 230px;
      }
      .line-code{
        background: linear-gradient(90deg, rgba(255, 169, 0, 0.1) 0%, #FFA900 50%, rgba(255, 169, 0, 0.1) 100%);
        width:100%;
        height: 3px;
      }
      .para{
        width: 270px;
      }
      @media only screen and (max-width: 1023px){
        .footer-bg{
            background-color:#000000;
              width:100%;
            height: auto;
            // background-size: cover;
            // background-repeat: no-repeat;
            // background-position: center;
            // background-position: cover;
            // background-size: 100% 100%;
    
          }  

          
      }
        
        
        
        
        `
    }
</style>




   </>

  )
}
